import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  svgHover: {
    fill: theme.palette.foreground.default,
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    transition: "all 0.5s ease",
  },
}));

export const Logo = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 40 400 400"
      className={classes.svgHover}
    >
      <path d="M173.1,96.7L200,42.8l0,0l26.9,53.9L200,150.6L97,357.2H43.3L173.1,96.7z M356.7,357.2H303l-89.5-179.6l26.7-54.2L356.7,357.2z M251.1,296.2H127.2v-30.9c35.8,0,71.7,0,107.5,0C240.2,275.5,245.6,285.9,251.1,296.2z" />
    </svg>
  );
};
