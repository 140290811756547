import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import DisplacementSphere from "../components/background/DisplacementSphere";
import Helmet from "react-helmet";
import style from "./notFoundStyle";
import Resume from "../settings/resume.json";

export const PageNotFound = () => {
  const [hover, setHover] = useState(false);

  return (
    <Container
      style={{
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        minHeight: "100vh",
      }}
      maxWidth="sm"
    >
      <Helmet>
        <title>
          {Resume.basics.username} | {Resume.basics.page.error}
        </title>
      </Helmet>
      <Typography>
        This page could not be found. It either doesn’t exist or was deleted.
      </Typography>
      <Typography>
        <Link
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          style={{
            ...style.normal,
            ...(hover ? style.hover : null),
          }}
          to="/"
        >
          <ArrowRightIcon />
          Back to homepage
        </Link>
      </Typography>
      <DisplacementSphere />
    </Container>
  );
};
