import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { TextDecrypt } from "../content/TextDecrypt";

const useStyles = makeStyles((theme) => ({
  footerText: {
    display: "flex",
    alignSelf: "center",
    transition: "all 0.5s ease",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "0.8em",
      marginLeft: theme.spacing(4),
    },
  },
}));

export const FooterText = () => {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.footerText}>
      <TextDecrypt
        text={`\u00a9 ${new Date().getFullYear()} ANARX. All rights reserved.`}
      />
    </Typography>
  );
};
