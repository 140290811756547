export default {
  normal: {
    color: "rgba(0, 191, 191, 0.45)",
    display: "flex",
    textDecoration: "none",
    cursor: "pointer",
    padding: "0 10px",
    boxShadow: "inset 0 0 0 0 rgba(255, 255, 255, 0.16)",
    transition: "all 0.4s ease-in-out 0s",
  },
  hover: {
    boxShadow: "inset 300px 0 0 0 rgba(255, 255, 255, 0.5)",
    color: "black",
    textDecoration: "none",
  },
};
